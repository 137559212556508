.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.dark {
  background-color: #181818;
  color:#fffff2;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

li {
  list-style-type: none; /* Hide the default marker */
}

.menu__link-header {
  font-size: 1.2rem;
  color: white;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
  text-decoration: none;
  opacity: 0.75;
  font-weight: 300;
}
.menu__link:hover{
  color: white;
}

.menu__link:hover {
  opacity: 1;
}

.rotate:not(:hover){
  transform: rotate(-360deg);
  transition-duration: 1.5s;
}
.rotate:hover{
  transform: rotate(360deg);
  transition-duration: 2s;
}


@media only screen and  (max-width: 500px) {
  .css-5lbw0b-MuiTypography-root {
    font-size: 10px;
  }
}

.menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  flex-wrap: wrap;
}

header .nav-link {
  display: block;

  color: white;
}

.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
background-color: #202020;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 100;

}

header a {
  color: #ffffff;
  display: block;
  /*float: left;*/
  text-align: center;
  text-shadow: 0 1px 0 #000;
  margin-left: 15%;
}


header a:hover {
  color: #F2A900;
  text-decoration: none; }


.mobile-nav ul {
  /*list-style: none;*/
  padding: 0;
  margin: 0;
}
.mobile-nav li {
  padding: 10px;
  /*border-bottom: 1px solid #ccc;*/
}
.mobile-nav a {
  text-decoration: none;

}
.mobile-nav-img{
  height:10%;
  width:auto;
}


.header-mobile{
  display: none;
}
@media only screen and  (max-width: 580px) {
  .header-mobile {
    display: block;
  }

}

/*timeline*/
/*@media only screen and  (max-width: 455px) {*/

/*   .timeline {*/
/*   font-size: 10px;*/
/*  }*/
/*}*/


@media only screen and  (max-width: 848px) {

  header .navbar-nav-scroll {
    display: none;
  }
}

.background{
  background-image: url("./img/react-background.jpg");
  width: 100%;
  background-size: cover;
  height: 100%;
}
.background-about{
  background-image: url("./img/reactspace.jpg");
  width: 70%;
  background-size: cover;
  /*height: 100%;*/
}
.background-projects{
  background-image: url("./img/Spring-Boot-React.png");
  width: 100%;
  /*background-size: cover;*/
  height: 100%;
}

.background-contact{
  background-image: url("./img/dockerReactSpring.jpg");
  width: 100%;
  /*background-size: cover;*/
  height: 80%;
}


.tile{
  background-color: #282828;
  color: #fffff2;
}


.hover-effect:hover {
  transition: transform 0.3s ease;
  transform: scale(1.1);
  cursor: pointer;
}
.hover-effect:not(:hover) {
  transition: transform 0.2s ease;
  transform: scale(1);
  cursor: pointer;
}
.parallax {
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  overflow-y: hidden;
}

/*.wave {*/
/*  position: absolute;*/
/*  top: -100px;*/
/*  left: 0;*/
/*  width: 100%;*/
/*  overflow-x: hidden;*/
/*  overflow-y: hidden;*/
/*  height: 100px;*/
/*  background: url("https://i.imgur.com/ZAts69f.png");*/
/*  background-size: 1000px 100px;*/
/*}*/

/*.wave#wave1 {*/
/*  z-index: 1000;*/
/*  opacity: 1;*/
/*  bottom: 0;*/
/*  animation: animateWaves 4s linear infinite;*/
/*}*/

/*.wave#wave2 {*/
/*  z-index: 999;*/
/*  opacity: 0.5;*/
/*  bottom: 10px;*/
/*  animation: animate 4s linear infinite !important;*/
/*}*/

/*.wave#wave3 {*/
/*  z-index: 1000;*/
/*  opacity: 0.2;*/
/*  bottom: 15px;*/
/*  animation: animateWaves 3s linear infinite;*/
/*}*/

/*.wave#wave4 {*/
/*  z-index: 999;*/
/*  opacity: 0.7;*/
/*  bottom: 20px;*/
/*  animation: animate 3s linear infinite;*/
/*}*/

/*@keyframes animateWaves {*/
/*  0% {*/
/*    background-position-x: 1000px;*/
/*  }*/
/*  100% {*/
/*    background-position-x: 0px;*/
/*  }*/
/*}*/

/*@keyframes animate {*/
/*  0% {*/
/*    background-position-x: -1000px;*/
/*  }*/
/*  100% {*/
/*    background-position-x: 0px;*/
/*  }*/
/*}*/

.footer {
  position: relative;
  background: #202020;
  color: #fffff2;
  /*min-height: 100px;*/
  /*padding: 20px 50px;*/
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /*margin-top:35%;*/
}